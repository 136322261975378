import axios from '@/utils/axios'
import moment from 'moment'
import Axios from 'axios'

import { defineStore } from 'pinia'
import { getSavedState, saveState } from '@/utils/helper'
import { useAuthStore } from './auth'
import { useNotificationStore } from './notification'

export const useEventStore = defineStore('event', {
  state: () => ({
    events: getSavedState('event.All'),
    event: null,
    store: getSavedState('event.Store'),
    tickets: {
      all: [],
      loading: false,
      error: null
    },
    loadEvents: false,
    loadEvent: false,
    etDonorJWT: getSavedState('event.etDonorJWT')
  }),
  getters: {
    getAllEvents(state) {
      return state.events
    },
    getEvent: (state) => state.event,
    getStore(state) {
      return state.store
    },
    getLoadEvents(state) {
      return state.loadEvents
    },
    getLoadEvent(state) {
      return state.loadEvent
    },
    getAllTickets(state) {
      return state.tickets.all
    },
    getDonorJWT(state) {
      return state.etDonorJWT
    },
    /**
     * Parse the claims from the access token and return them with no additional checks.
     * @returns {object|null}
     */
    getCustomerTokenClaims(state) {
      const token = state.etDonorJWT?.accessToken
      if (!token) {
        return null
      }

      const tokenParts = token.split('.')
      return JSON.parse(window.atob(tokenParts[1]))
    },
    /**
     * Parse the claims from the access token, validate them, and log out if the token is invalid.
     * @returns {bool}
     */
    isCustomerTokenValid(state) {
      const claims = this.getCustomerTokenClaims
      if (!claims) {
        return false
      }

      const now = Math.floor(Date.now() / 1000)

      if (claims.exp && claims.exp < now) {
        return false
      }

      // basically the same as above, but we'll double check
      if (
        claims.iat &&
        state.etDonorJWT?.expiresIn &&
        claims.iat + state.etDonorJWT?.expiresIn < now
      ) {
        return false
      }

      return true
    }
  },
  actions: {
    async fetchStoreInfo(charity_id) {
      if (this.store) {
        return Promise.resolve(this.store)
      }

      return axios
        .get(`/event-service/store/charity/${charity_id}`)
        .then((Response) => {
          this.store = Response.data.data
          saveState('event.Store', Response.data.data)
          return Response.data
        })
        .catch((Error) => {
          const notification = useNotificationStore()
          notification.getNotificationError(Error)
          return Promise.reject(notification.getNotification?.message || 'Error')
        })
    },
    async fetchAllEvents(storeId) {
      if (getSavedState('event.All')) {
        return Promise.resolve(getSavedState('event.All'))
      } else {
        this.loadEvents = true
        return axios
          .get(`/event-service/events`, {
            headers: {
              'X-Harness-Store-ID': storeId
            }
          })
          .then((Response) => {
            this.events = Response.data.data.events
            const expiresAt = moment().add(
              (import.meta.env.VITE_APP_CACHING_TIME * 5) / 60,
              'minutes'
            )
            saveState('event.All', this.events, expiresAt)
            this.loadEvents = false
            return Response.data
          })
          .catch((Error) => {
            this.loadEvents = false
            const notification = useNotificationStore()
            notification.getNotificationError(Error)
            return Promise.reject(notification.getNotification?.message || 'Error')
          })
      }
    },
    async fetchEvent({ storeId, eventId }) {
      this.loadEvent = true

      return axios
        .get(`/event-service/event/${eventId}`, {
          headers: {
            'X-Harness-Store-ID': storeId
          }
        })
        .then((Response) => {
          this.event = Response.data.data.event
          return Response.data
        })
        .catch((Error) => {
          const notification = useNotificationStore()
          notification.getNotificationError(Error)
          return Promise.reject(notification.getNotification?.message || 'Error')
        })
        .finally(() => (this.loadEvent = false))
    },
    async searchAddress({ address }) {
      const searchUrl = new URL('https://nominatim.openstreetmap.org/search.php')
      searchUrl.searchParams.append('q', address)
      searchUrl.searchParams.append('format', 'jsonv2')

      return Axios.get(searchUrl.toString()).then((Response) => {
        return Response.data
      })
    },
    async fetchTickets({ storeId, eventId }) {
      try {
        this.tickets.all = []
        this.tickets.loading = true
        this.tickets.error = null

        const response = await axios.get(`/event-service/event/${eventId}/tickets`, {
          headers: {
            'X-Harness-Store-ID': storeId
          }
        })

        this.tickets.all = response.data.data
        return this.tickets[eventId]
      } catch (error) {
        const notification = useNotificationStore()
        notification.getNotificationError(Error)
        this.tickets.error = notification.getNotification?.message || 'Failed to load tickets'

        throw error
      } finally {
        this.tickets.loading = false
      }
    },
    async loginCustomer(storeId) {
      const authStore = useAuthStore()
      return axios
        .post(
          `event-service/customer/login`,
          {
            jwt: authStore.getToken
          },
          {
            headers: {
              'X-Harness-Store-ID': storeId
            }
          }
        )
        .then((Response) => {
          this.etDonorJWT = Response.data.data.auth
          saveState('event.etDonorJWT', this.etDonorJWT)
          return this.etDonorJWT
        })
        .catch((Error) => {
          const notification = useNotificationStore()
          notification.getNotificationError(Error)
          return Promise.reject(notification.getNotification?.message || 'Error')
        })
    },
    async fetchTransaction(transactionId, accessToken, storeId) {
      return axios
        .get(`/event-service/transaction/${transactionId}/receipt`, {
          headers: {
            'X-Harness-Store-ID': storeId
          },
          params: {
            accessToken
          }
        })
        .then((Response) => {
          return Response.data
        })
        .catch((Error) => {
          const notification = useNotificationStore()
          notification.getNotificationError(Error)
          return Promise.reject(notification.getNotification?.message)
        })
    },
    logOutCustomer() {
      this.etDonorJWT = null
      saveState('event.etDonorJWT', null)
    }
  }
})
